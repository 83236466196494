import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

const ProjectsPage = () => (
  <Layout>
    <SEO title="Recent Projects" image={"/brookside.jpg"} />

    <section>
      <div className="container">
        <h1 className="section-heading">Recent Projects</h1>

        <div className="row">
          <div className="col-md-6 col-xl-4 mb-4">
            <Link to="/projects/brookside-subdivision">
              <div className="project-tile">
                <StaticImage
                  aspectRatio={16 / 11}
                  transformOptions={{ cropFocus: "south" }}
                  objectPosition="50% 100%"
                  src="../../images/brookside.jpg"
                  alt="Brookside Subdivision"
                />
                <h5>
                  Brookside Subdivision <i className="fas fa-arrow-right"></i>
                </h5>
              </div>
            </Link>
          </div>
          <div className="col-md-6 col-xl-4 mb-4">
            <Link to="/projects/criterion-lane-carpark">
              <div className="project-tile">
                <StaticImage
                  aspectRatio={16 / 11}
                  transformOptions={{ cropFocus: "center" }}
                  objectPosition="50% 50%"
                  src="../../images/asphalt.jpg"
                  alt="Carpark"
                />
                <h5>
                  Criterion Lane Car Park <i className="fas fa-arrow-right"></i>
                </h5>
              </div>
            </Link>
          </div>
          <div className="col-md-6 col-xl-4 mb-4">
            <Link to="/projects/fox-street-subdivision">
              <div className="project-tile">
                <StaticImage
                  aspectRatio={16 / 11}
                  transformOptions={{ cropFocus: "south" }}
                  objectPosition="50% 50%"
                  src="../../images/sub-divisions.jpg"
                  alt="Fox Street Subdivison"
                />
                <h5>
                  Fox Street Subdivison <i className="fas fa-arrow-right"></i>
                </h5>
              </div>
            </Link>
          </div>
          <div className="col-md-6 col-xl-4 mb-4">
            <Link to="/projects/featherston-station-upper-hutt-station-carpark-extensions">
              <div className="project-tile">
                <StaticImage
                  aspectRatio={16 / 11}
                  transformOptions={{ cropFocus: "center" }}
                  objectPosition="50% 50%"
                  src="../../images/featherston.jpg"
                  alt="Crane"
                />
                <h5>
                  Featherston Station & Upper Hutt Station Car Park Extensions
                  <i className="fas fa-arrow-right"></i>
                </h5>
              </div>
            </Link>
          </div>
          <div className="col-md-6 col-xl-4 mb-4">
            <Link to="/projects/opaki-road-stormwater-upgrades">
              <div className="project-tile">
                <StaticImage
                  aspectRatio={16 / 11}
                  transformOptions={{ cropFocus: "center" }}
                  objectPosition="50% 50%"
                  src="../../images/opaki2.jpg"
                  alt="Opaki stormwater"
                />
                <h5>
                  Opaki Road Stormwater Upgrades{" "}
                  <i className="fas fa-arrow-right"></i>
                </h5>
              </div>
            </Link>
          </div>
          <div className="col-md-6 col-xl-4 mb-4">
            <Link to="/projects/brown-owl-subdivision">
              <div className="project-tile">
                <StaticImage
                  aspectRatio={16 / 11}
                  transformOptions={{ cropFocus: "center" }}
                  objectPosition="50% 50%"
                  src="../../images/brownowl.jpg"
                  alt="Digger"
                />
                <h5>
                  Brown Owl Subdivision <i className="fas fa-arrow-right"></i>
                </h5>
              </div>
            </Link>
          </div>
          <div className="col-md-6 col-xl-4 mb-4">
            <Link to="/projects/mains-water-and-fire-hydrant-replacement-at-tararua-college">
              <div className="project-tile">
                <StaticImage
                  aspectRatio={16 / 11}
                  transformOptions={{ cropFocus: "center" }}
                  objectPosition="50% 50%"
                  src="../../images/tararua.jpg"
                  alt="mains water"
                />
                <h5>
                  Mains Water and Fire Hydrant Replacement at Tararua College
                  <i className="fas fa-arrow-right"></i>
                </h5>
              </div>
            </Link>
          </div>
          <div className="col-md-6 col-xl-4 mb-4">
            <Link to="/projects/epic-way-subdivision">
              <div className="project-tile">
                <StaticImage
                  aspectRatio={16 / 11}
                  transformOptions={{ cropFocus: "center" }}
                  objectPosition="50% 50%"
                  src="../../images/epicway.jpg"
                  alt="driveway"
                />
                <h5>
                  Epic Way Subdivision <i className="fas fa-arrow-right"></i>
                </h5>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ProjectsPage;
